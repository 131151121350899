import React, { useState } from 'react';

import Post from './post';

const OFFSET = 9;

const Posts = ({ posts }) => {
  const [limit, setLimit] = useState(15);

  return (
    <div>
      <ol
        style={{
          listStyle: `none`,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {posts
          .slice(0, limit)
          .map(post => <Post key={post.fields.slug} post={post} />)}
      </ol>
      <div className="loadmore">
        {posts.length > limit && (
          <a onClick={() => setLimit(limit + OFFSET)}>Load More</a>
        )}
      </div>
    </div>
  );
};

export default Posts;
