/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import About from './about';

const Bio = () => {
  const [aboutOpen, toggleAbout] = useState(false);

  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 250, height: 333, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `);

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author;
  const avatar = data?.avatar?.childImageSharp?.fixed;

  return (
    <div className="bio">
      {avatar && (
        <div
          role="banner"
          onClick={() => toggleAbout(!aboutOpen)}
          onKeyDown={() => toggleAbout(!aboutOpen)}
        >
          <Image
            fixed={avatar}
            alt={author?.name || ``}
            className={aboutOpen ? 'bio-avatar-active' : 'bio-avatar'}
          />
        </div>
      )}
      {aboutOpen && <About />}
    </div>
  );
};

export default Bio;
