import React from 'react';
import { graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Posts from '../components/posts';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  return posts.length === 0 ? (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <p>No posts found.</p>
    </Layout>
  ) : (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <Posts posts={posts} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM YYYY")
          title
          description
          banner {
            childImageSharp {
              fixed(width: 240, quality: 95) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;
