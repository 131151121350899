import Image from 'gatsby-image';
import styled from 'styled-components';

const BannerImage = styled(Image)`
  border-radius: 5px;
  max-width: 400px;
  margin-top: 20px;
`;

export default BannerImage;
