import React from 'react';

const About = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '380px',
        maxWidth: '380px',
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '11px',
        textAlign: 'center',
      }}
    >
      <br />
      <br />
      <div style={{ textAlign: 'center', paddingBottom: '15px' }}>
        <strong>Currently retired--will code for lols.</strong>
        <br />
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '15px' }}>
        <strong>GitHub: </strong>
        <br />
        <a href="https://github.com/againer" target="_blank" rel="noreferrer">
          (very private)
        </a>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '15px' }}>
        <strong>Academic Background: </strong>
        <br />
        BS and MS in CS
      </div>
      <br />
    </div>
  );
};

export default About;
