import React from 'react';

import { Link } from 'gatsby';
import Banner from './banner';

const Post = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug;

  return (
    <li
      key={post.fields.slug}
      style={{
        flex: '0 2 auto',
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h2>
            <Link to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h2>
          <small>{post.frontmatter.date}</small>
        </header>
        <section>
          <Link to={post.fields.slug}>
            <Banner
              fixed={post.frontmatter.banner.childImageSharp.fixed}
              alt="Banner Image"
            />
          </Link>
        </section>
      </article>
    </li>
  );
};

export default Post;
